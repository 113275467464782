@charset "UTF-8";
// ==========================================================================
// print
// ==========================================================================
@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
  section {
    display: block;
  }
  .c-fadeIn {
    visibility: visible;
    opacity: 1;
  }
}